<template>
  <div class="step-body step-2">
    <!-- radio box -->
    <div class="form-wrapper">
      <base-input>
        <el-radio-group v-model="customerSelectionMethod">
          <el-radio :label="CUSTOMER_CHOICE_SELECT">{{
            $t("BOOKINGS.EXISTING_CUSTOMER")
          }}</el-radio>
          <el-radio :label="CUSTOMER_CHOICE_CREATE">{{
            $t("BOOKINGS.CREATE_CUSTOMER")
          }}</el-radio>
        </el-radio-group>
      </base-input>
    </div>

    <div class="form-wrapper">
      <base-input v-if="customerSelectionMethod === CUSTOMER_CHOICE_SELECT">
        <customer-selector
          :customer="booking.customer?.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="booking.organization?.id"
          @customerChanged="
            (customerId, customer) => {
              booking.customer.id = customerId;
              selectedCustomer = customer;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.customer" />
    </div>

    <div v-if="selectedCustomer" class="customer-details">
      <dl class="row">
        <dt>Nom du client</dt>
        <dd>
          {{ selectedCustomer.firstname + " " + selectedCustomer.lastname }}
        </dd>
      </dl>
      <dl class="row">
        <dt>Couriel</dt>
        <dd>
          {{ selectedCustomer.email }}
        </dd>
      </dl>
      <dl class="row">
        <dt>Téléphone</dt>
        <dd>
          {{ selectedCustomer.phone }}
        </dd>
      </dl>
      <dl class="row">
        <dt>Pays</dt>
        <dd>
          {{ selectedCustomer.country }}
        </dd>
      </dl>
      <dl class="row">
        <dt>Ville</dt>
        <dd>
          {{ selectedCustomer.city }}
        </dd>
      </dl>
    </div>

    <div class="buttons-group">
      <el-button
        v-if="customerSelectionMethod === CUSTOMER_CHOICE_SELECT"
        class="continue"
        type="primary"
        @click="
          () => {
            handleSubmit(true);
          }
        "
      >
        {{ $t("BOOKINGS.SECURE_BOOKING") }}
      </el-button>
      <el-button
        v-if="customerSelectionMethod === CUSTOMER_CHOICE_SELECT"
        type="secondary"
        class="add"
        @click="
          () => {
            handleSubmit(false);
          }
        "
      >
        {{ $t("BOOKINGS.ADD_BOOKING") }}
      </el-button>
      <el-button
        class="previous"
        type="primary"
        @click="
          () => {
            gotToStep(0);
          }
        "
      >
        {{ $t("COMMON.PREVIOUS") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Option,
  Radio,
  RadioGroup,
  Row,
  Select,
  Step,
  Steps,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError";
import CustomerSelector from "@/components/CustomerSelector";
import defaultBooking from "../../defaultBooking";
import defaultSpot from "../../../../SpotModule/SpotManagement/defaultSpot";
import { BOOKING_STATUS_PENDING } from "@/constants/common";

const CUSTOMER_CHOICE_SELECT = "SELECT";
const CUSTOMER_CHOICE_CREATE = "CREATE";

export default {
  name: "booking-customer-selection",

  components: {
    CustomerSelector,
    ValidationError,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
  },

  data() {
    return {
      booking: {
        ...defaultBooking,
        ...this.bookingData,
      },
      customerSelectionMethod: CUSTOMER_CHOICE_SELECT,
      selectedCustomer: null,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      formErrors: null,
      CUSTOMER_CHOICE_SELECT,
      CUSTOMER_CHOICE_CREATE,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit(secure = false) {
      await this.sendInformation(secure);
    },

    async sendInformation(secure = false) {
      this.loading = true;

      this.booking.status = BOOKING_STATUS_PENDING;

      try {
        await this.$store.dispatch("bookings/update", this.booking);
        this.$emit("customerSelected", this.booking, secure);
      } catch (error) {
        this.formErrors = error.response.data.errors;
      }

      this.loading = false;
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },

    gotToStep(stepNumber) {
      this.$emit("stepChanged", stepNumber);
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...defaultBooking,
            ...this.booking,
            ...cloneDeep(bookingData),
          };
        }
      },
      deep: true,
    },
  },
};
</script>
