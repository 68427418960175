<template>
  <div class="container-fluid">
    <booking-form
      :loading="loading"
      :bookingData="booking"
      :spot="spot"
      :formErrors="formErrors"
      @bookingCreated="onBookingCreated"
      @onCloseBookingModal="closeBookingModal"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultBooking from "../defaultBooking";
import BookingForm from "../partials/BookingForm.vue";
import defaultSpot from "../../../SpotModule/SpotManagement/defaultSpot";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BookingForm,
  },

  mixins: [alertLeave],

  props: {
    spotId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: cloneDeep(defaultBooking),
      spot: defaultSpot,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.getSpot();
  },

  methods: {
    async getSpot() {
      this.loading = true;
      try {
        await this.$store.dispatch("spots/get", this.spotId);
        this.spot = this.$store.getters["spots/spot"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },
    onBookingCreated(booking, secure) {
      if (!secure) {
        this.$emit("onCloseBookingModal", true);
      } else {
        history.pushState(
            {},
            null,
            this.$router.resolve({
              name: "Pending Bookings",
              query: {id: booking.id, action: 'confirm'},
            }).href
        );
      }
    },
    closeBookingModal() {
      this.$emit("onCloseBookingModal", true);
    },
  },
};
</script>
