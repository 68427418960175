<template>
  <div class="step-body step-1">
    <!-- start_at, end_at -->
    <div class="form-wrapper width_1">
      <base-input class="form-wrapper full">
        <el-date-picker
          v-model="selectedDateRange"
          type="daterange"
          start-placeholder="Date de début"
          end-placeholder="Date de fin"
          format="dd MMM yyyy"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :picker-options="{ disabledDate: isDisabledDate }"
          @change="onDateSelected"
        />
      </base-input>
    </div>

    <!-- Organization -->
    <div class="form-wrapper width_1">
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <organization-selector
          :organization="booking.organization?.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
              booking.organization.id = organizationId;
              onFormChanged();
            }
          "
          :disabled="!!booking.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <!-- adult capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_ADULT_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          v-model="booking.adults_count"
          @change="
            (capacity) => {
              booking.adults_count = capacity;
              onFormChanged();
            }
          "
        >
          <el-option
            v-for="n in maxAdults + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.adults_count" />
    </div>

    <!-- children capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_CHILDREN_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          v-model="booking.children_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxChildren + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.children_count" />
    </div>

    <!-- pets capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_PETS_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
          v-model="booking.pets_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxPets + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.pets_count" />
    </div>

    <div class="form-wrapper width_1">
      <label class="pers-count">
        Nombre de personnes :
        {{ booking.adults_count + booking.children_count }} pers
      </label>
    </div>

    <!-- equipment length -->
    <div class="form-wrapper width_1">
      <base-input
        :label="`${$t('SPOTS.EQUIPMENT_LENGTH')}`"
        type="number"
        :max="maxEquipmentLength"
        v-model="booking.equipment_length"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.equipment_length" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.SPECIAL_REQUEST')"
        :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')"
      >
        <el-input type="textarea" v-model="booking.special_request"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.special_request" />
    </div>

    <div v-if="pricing.totalPrice" class="form-wrapper width_1 summary">
      <dl class="row">
        <dt>{{ spot.unit_price }} CAD (x {{ this.pricing.duration }} jours)</dt>
        <dd>{{ pricing.subTotalPrice }} CAD</dd>
      </dl>
      <dl class="row">
        <dt>TVS</dt>
        <dd>{{ pricing.tps.toFixed(2) }} CAD</dd>
      </dl>
      <dl class="row">
        <dt>TVQ</dt>
        <dd>{{ pricing.tvq.toFixed(2) }} CAD</dd>
      </dl>
      <dl class="row total">
        <dt>Total</dt>
        <dd>{{ pricing.totalPrice.toFixed(2) }} CAD</dd>
      </dl>
    </div>

    <div class="form-wrapper width_1 buttons-group">
      <el-button class="continue" type="primary" @click="handleSubmit">
        {{ $t("COMMON.CONTINUE") }}
      </el-button>
      <el-button class="outline" type="secondary" @click="handleSubmit">
        {{ $t("COMMON.SAVE_FOR_LATER") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError";
import OrganizationSelector from "@/components/OrganizationSelector";
import defaultBooking from "../../defaultBooking";
import defaultSpot from "../../../../SpotModule/SpotManagement/defaultSpot";

export default {
  name: "booking-spot-selection",

  components: {
    OrganizationSelector,
    ValidationError,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
  },

  data() {
    this.fetchBookingList();

    let selectedDateRange = [];

    if (this.bookingData.start_at) {
      selectedDateRange = [
        this.parseBookingDate(this.bookingData.start_at),
        this.parseBookingDate(this.bookingData.end_at),
      ];
    }

    return {
      booking: this.bookingData,
      selectedDateRange: selectedDateRange,
      pricing: {
        duration: null,
        subTotalPrice: null,
        tps: null,
        tvq: null,
        totalPrice: null,
      },
      bookingList: [],
      maxAdults: 10,
      maxChildren: 10,
      maxPets: 10,
      maxEquipmentLength: 10,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      formErrors: null,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      await this.sendInformation();
    },

    async sendInformation() {
      this.loading = true;

      if (!this.booking.customer?.id) {
        delete this.booking.customer;
      }

      if (!this.booking.spot?.id) {
        this.booking.spot.id = this.spot.id;
      }

      try {
        if (this.booking.id) {
          await this.$store.dispatch("bookings/update", this.booking);
        } else {
          await this.$store.dispatch("bookings/add", this.booking);
        }

        const createdBooking = await this.$store.getters["bookings/booking"];
        this.booking.id = createdBooking.id;

        this.$emit("draftBookingCreated", this.booking);
      } catch (error) {
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },

    async fetchBookingList() {
      if (!this.spot) {
        return false;
      }

      try {
        this.loading = true;
        const today = new Date();
        const afterThreeMonths = new Date();
        afterThreeMonths.setMonth(today.getMonth() + 3);
        let params = {
          filter: {
            ...{
              spot_id_in: [this.spot.id],
              date_range: [today, afterThreeMonths],
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };

        if (this.booking?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.booking?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("bookings/list", params);
        this.bookingList = this.$store.getters["bookings/list"];
        this.loading = false;
      } catch (error) {
        console.warn(error);
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    isDisabledDate(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      let disabled = false;
      this.bookingList?.forEach((booking) => {
        const startAt = this.parseBookingDate(booking.start_at);
        const endAt = this.parseBookingDate(booking.end_at);

        if ((date <= endAt && date >= startAt) || date < new Date()) {
          disabled = true;

          return disabled;
        }
      });

      return disabled;
    },

    parseBookingDate(d) {
      let date = new Date(d);

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      return date;
    },

    calculatePricing(startAt, endAt) {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      const differenceMs = Math.abs(startAt - endAt);
      this.pricing.duration = Math.round(differenceMs / ONE_DAY);
      this.pricing.subTotalPrice = this.spot.unit_price * this.pricing.duration;
      this.pricing.tps = this.pricing.subTotalPrice * 0.05;
      this.pricing.tvq = this.pricing.subTotalPrice * 0.09975;
      this.pricing.totalPrice =
        this.pricing.subTotalPrice + this.pricing.tps + this.pricing.tvq;
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },
    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.onFormChanged();
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    selectedDateRange(newDateRange) {
      const startAt = this.parseBookingDate(newDateRange[0]);
      const endAt = this.parseBookingDate(newDateRange[1]);

      const differenceMs = Math.abs(startAt - endAt);

      this.calculatePricing(startAt, endAt);
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };
        }
      },
      deep: true,
    },
    spot: {
      handler: function (spot) {
        if (spot) {
          this.fetchBookingList();

          this.maxAdults = spot.adults_capacity;
          this.maxChildren = spot.children_capacity;
          this.maxPets = spot.pets_capacity;
          this.maxEquipmentLength = spot.equipment_length;
        }
      },
      deep: true,
    },
  },
};
</script>
