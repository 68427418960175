var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-body step-2"},[_c('div',{staticClass:"form-wrapper"},[_c('base-input',[_c('el-radio-group',{model:{value:(_vm.customerSelectionMethod),callback:function ($$v) {_vm.customerSelectionMethod=$$v},expression:"customerSelectionMethod"}},[_c('el-radio',{attrs:{"label":_vm.CUSTOMER_CHOICE_SELECT}},[_vm._v(_vm._s(_vm.$t("BOOKINGS.EXISTING_CUSTOMER")))]),_c('el-radio',{attrs:{"label":_vm.CUSTOMER_CHOICE_CREATE}},[_vm._v(_vm._s(_vm.$t("BOOKINGS.CREATE_CUSTOMER")))])],1)],1)],1),_c('div',{staticClass:"form-wrapper"},[(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT)?_c('base-input',[_c('customer-selector',{attrs:{"customer":_vm.booking.customer?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.booking.organization?.id},on:{"customerChanged":(customerId, customer) => {
            _vm.booking.customer.id = customerId;
            _vm.selectedCustomer = customer;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1),(_vm.selectedCustomer)?_c('div',{staticClass:"customer-details"},[_c('dl',{staticClass:"row"},[_c('dt',[_vm._v("Nom du client")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.firstname + " " + _vm.selectedCustomer.lastname)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v("Couriel")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.email)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v("Téléphone")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.phone)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v("Pays")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.country)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v("Ville")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.city)+" ")])])]):_vm._e(),_c('div',{staticClass:"buttons-group"},[(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT)?_c('el-button',{staticClass:"continue",attrs:{"type":"primary"},on:{"click":() => {
          _vm.handleSubmit(true);
        }}},[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.SECURE_BOOKING"))+" ")]):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT)?_c('el-button',{staticClass:"add",attrs:{"type":"secondary"},on:{"click":() => {
          _vm.handleSubmit(false);
        }}},[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.ADD_BOOKING"))+" ")]):_vm._e(),_c('el-button',{staticClass:"previous",attrs:{"type":"primary"},on:{"click":() => {
          _vm.gotToStep(0);
        }}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PREVIOUS"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }